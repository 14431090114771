export let environment = {
  api_backend_url: 'https://middle-qa.lleego.es',
  api_url: window.location.href.includes('veci') ? 'https://veci-api-qa.lleego.es': 'https://api-qa.lleego.es',
  api_rent_a_car_uri: '/api/v2/rentacar',
  //api_url: 'https://veci-api-qa.lleego.es', // it value changes user.service
  backend_error_logs: '/api/logs/front',
  carsSearchBaseUrl: '/transport/cars/avail',
  trainsSearchBaseUrl: '/transport/trains/avail',
  dev_api_url: null,
  //dev_api_url: 'https://veci-api-qa.lleego.es',
  env: 'staging',
  iframeUrl: 'https://market.lleego.com',
  phoneWidth: 992,
  private_rent_a_car_uri: '/private/v2/rentacar',
  production: true,
  searchBaseUrl: '/transport/flight',
  searchBaseUrlCC: '/call-center/b2c/transport/flight',
  socketIo: 'wss://offline.lleego.com/ws/',
  urlCO2: 'https://co2.lleego.es/co2calculator',
  logoutIberiaCC: 'https://sso.lleego.com/saml/login',
  veci_domains: [
    'veci.lleego.com',
    'veciapi.lleego.com',
    'veci-app-dev.lleego.com',
    'veci-api-dev.lleego.com',
    'test-api-vc.lleego.com',
    'veci-frontv2.lleego.es',
    'api-vc.lleego.com',
    'veci-cars.lleego.com',
    'veci-frontv2-prod-qa.lleego.es',
  ],
  veciDisneyBudgetButtonURL: 'https://intranet.viajeselcorteingles.es',
  xApiKey: 'cwfEt9hbo07Sbm13p0jgt1kyU6eWy6VY2FNd6oNq',
  carsResultsGoogleKey: 'AIzaSyBGeO3IvBYd6haJoA91kq0aySkNZgn1CMw',
  vistatravel_url: 'https://b2b.vistatravel.es',
  vistatravel_url_credit_balance:
    'https://b2b.avoristravel.com/access/force?callback_url=https://b2b.avoristravel.com/corporate-customer/%23/credit-balance',
  vistatravel_ID_control: [1353, 1398],
  prismaTokenApiKey: 'a1d2b6531ac24ae8b3e91ae803cce04a',
  vistatravelAllMigrated: false,
  baseURLS3Document:
    'https://lleego-london.s3.eu-west-2.amazonaws.com/public/documents/',
  quarantine_url:
    'https://igqtojcm3moyk7xkeojmmxrjqq0zwxho.lambda-url.eu-west-2.on.aws',
  payway_sdk_javascript: 'https://live.decidir.com/api/v2',
  sabreRailAPPSessionSecond: 180,
  sending_itinerary_ib: 'https://www.iberia.com/es/gestion-de-reservas/?language=es&market=es&channel=COM#!/chktrp',
  isIBCCPaymentCash: true,
  clientsControlProviderFIlter:[] as string[],
  internalUsersEnvBaseUrls:[ // Lista de URLs base
    'http://localhost:4200',
    'https://frontv2-prod-qa.lleego.es',
    'https://app.lleego.com'
  ]
};
